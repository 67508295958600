body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  display: flex;
}
.sidebar {
  width: 200px;
  background-color: #1e3477;
  color: #fff;
  padding: 40px 20px;
}
.sidebar a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  width:100%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.sidebar ul {
  list-style: none;
  padding: 0;
}
.sidebar li {
  border-radius: 10px;
  
}
.sidebar a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.content {
  background-color: "#fff" !important;
}

.header {
  height: 32px !important;
  background-color: #fff !important;
}
.sidebar-logo-container{
display:flex;
justify-content:center;
}
